<template>
  <va-tree-root>
    <va-tree-category :label="$t(getLabel(label))">
      <va-tree-node
        v-for="node in category"
        :key="node.id"
      >
        <va-checkbox
          slot="checkbox"
          :disabled="!editable"
          v-model="node.allow"
          v-if="node.allow !== undefined"
        />
        {{ $t(getLabel(node.alias)) }}
      </va-tree-node>
    </va-tree-category>
  </va-tree-root>
</template>

<script>
export default {
  name: 'permission-view',
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    label: {
      type: String,
      default: '',
    },
    category: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getLabel (label) {
      return 'groups.permission.labels.' + label
    },
  },
}
</script>
